import { notification } from "src/constants/message";
import moment from "moment";

export const imageTypes = ["png", "jpg", "jpeg", "gif", "webp"];

export const compareById = (a, b) => {
  if (a.sequence === 0) {
    return 1; // Place 'a' at the end
  } else if (b.sequence === 0) {
    return -1; // Place 'b' at the end
  } else {
    return a.sequence - b.sequence; // Sort normally by ID
  }
};

export let showNotify = (status, message) => {
  let notifications = notification({
    type: status == 1 ? "success" : status == 2 ? "info" : "error",
    message: message || "Something went Wrong Please try Again",
  });
  notifications();
};

export const formatText = (message, selection, type) => {
  const newMessage = [
    message.slice(0, selection[0]),
    type == "b" ? "*" : type == "i" ? "_" : type == "s" ? "~" : "",
    message.slice(selection[0], selection[1]),
    type == "b" ? "*" : type == "i" ? "_" : type == "s" ? "~" : "",
    message.slice(selection[1]),
  ].join("");
  return newMessage;
};

export const sortByDate = async (data, field) =>
  data?.sort(
    (a, b) =>
      new Date(moment(a[field], "DD-MM-YYYY").format("YYYY-MM-DD")) -
      new Date(moment(b[field], "DD-MM-YYYY").format("YYYY-MM-DD"))
  );

export const showRequired = <span className="text-danger"> *</span>;

export const lightGreen = "#d5fdd9";
export const lightYellow = "#fbeeb8";
export const skyBlue = "#c1effe";
export const lightPurple = "#f7f4e4";
