import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { notification } from "src/constants/message";
import Select from "react-select";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CRow,
  CCardGroup,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CTableDataCell,
  CFormCheck,
} from "@coreui/react";
import { fetchUserById } from "src/api/common";
import { insertLead } from "src/api/commonapi";

import "./inquiryform.css";
import { Routes } from "src/constants/api_url";
import CustomImage from "src/views/CommonFunctions/CustomImage";
//-----------------------------------------------------------------------

const InquiryForm = () => {
  const [userData, setUserData] = useState([]);
  const [formdata, setformdata] = useState({});
  const [sresp, setresp] = useState(0);
  const [step, setStep] = useState(0);
  const [submitResponse, setSubmitResponse] = useState(0);
  const [existingLeadInsertModal, setExistingLeadInsertModal] = useState({
    visible: false,
    data: {},
  });

  let prefixoption = [
    { value: 3, label: "Dr." },
    { value: 4, label: "M/S" },
    { value: 5, label: "Master" },
    { value: 2, label: "Miss." },
    { value: 0, label: "Mr." },
    { value: 1, label: "Mrs." },
    { value: 6, label: ":" },
  ];

  let serviceOption = [
    { value: 10, label: "Attestation" },
    { value: 17, label: "Apostille" },
    { value: 20, label: "Translation" }, //translation
    { value: 8, label: "Visa" },
  ];
  let { id, type } = useParams();
  let u_id = 0;
  let date = moment().format("DD-MM-YYYY");
  if (id != undefined) {
    u_id = Buffer.from(id, "base64").toString("ascii");
  }

  useEffect(async () => {
    let resp = await fetchUserById(u_id);
    // console.log("resp", resp);
    setUserData(resp);
    setStep(0);
    setformdata({
      l_date: date,
      priority: 2,
      l_source: resp?.is_marketing == 1 ? 271 : 599,
      l_status: 1,
      l_branch_id: resp?.branch_id,
      l_created_by:
        resp?.marketing_parent_id == 0 ? u_id : resp?.marketing_parent_id,
      l_created_branch_id: resp?.branch_id,
      l_allocate_to:
        resp?.marketing_parent_id == 0 ? u_id : resp?.marketing_parent_id,
      reference_id: resp?.is_marketing == 1 ? u_id : 0,
      l_name: "",
      co_no: "",
      l_email: "",
      l_address: "",
      l_city: "",
      l_service: type ? type : 0,
      l_name_prefix: ":",
      f_url: resp?.f_url,
    });
  }, [sresp]);

  const submitData = async (e) => {
    const phoneRegex = /^[0-9]{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formdata.co_no.match(phoneRegex)) {
      let notify = notification({
        type: "error",
        message: "Please enter a valid 10-digit mobile number.",
      });
      notify();
    } else if (!formdata.l_email.match(emailRegex)) {
      let notify = notification({
        type: "error",
        message: "Please enter a valid email address.",
      });
      notify();
    } else if (
      formdata.l_name == undefined ||
      formdata.l_name == "" ||
      formdata.co_no == undefined ||
      formdata.co_no == "" ||
      formdata.l_email == undefined ||
      formdata.l_email == "" ||
      formdata.l_city == undefined ||
      formdata.l_city == "" ||
      formdata.l_service == undefined ||
      formdata.l_service == 0 ||
      formdata.l_service == undefined ||
      formdata.l_service == -1
    ) {
      let notify = notification({
        type: "error",
        message: "Please Fill All Data",
      });
      notify();
    } else {
      setStep(1);
      const resp = await insertLead(JSON.stringify(formdata));
      if (resp.status == 0) {
        let notify = notification({
          type: "error",
          message: resp.message,
        });
        notify();
        setStep(0);
      }
      if (resp.status == 1 || resp.status == 4) {
        setSubmitResponse(1);
        setStep(0);
        setformdata({});
        if (
          formdata?.f_url != undefined &&
          formdata?.f_url != null &&
          formdata?.f_url != ""
        ) {
          window.location.replace(`${formdata.f_url}`);
        }
        setresp(sresp + 1);
      }
      if (resp.status == 2) {
        setStep(0);
        setExistingLeadInsertModal({
          visible: true,
          data: {
            assignee_no: resp?.assignee_no,
            assignee_email: resp?.assignee_email,
          },
        });
      }
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "8px",
      border: "1px solid #998E81",
    }),
    menu: (provided) => ({
      ...provided,
      padding: "5px",
      border: "1px solid black",
    }),
  };
  return (
    <>
      <div className="d-flex flex-row">
        <CContainer>
          {submitResponse == 0 ? (
            <>
              <div className="">
                <div
                  className=" bg-white "
                  style={{
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                >
                  <CRow className="mb-3">
                    <CCol md={12} style={{ textAlign: "center" }}>
                      <div className="mb-2">
                        <img
                          src={`${Routes.API_HOSTNAME}/images/adminlogo/loginlogo.png`}
                          width="100px"
                          height="100px"
                          style={{ objectFit: "contains" }}
                        />
                        <h5
                          className="form_title"
                          style={{ margin: "8px 0 ", textAlign: "center" }}
                        >
                          ONLINE APOSTILLE & ATTESTATION FORM
                        </h5>
                      </div>
                    </CCol>
                  </CRow>
                  <CForm>
                    <CCol md={12} xs={12} className="mb-2">
                      <CFormInput
                        type="text"
                        className="p-3 custom-input"
                        style={{ border: "1px solid #998E81" }}
                        value={formdata?.l_name}
                        id="exampleFormControlInput1"
                        name="l_name"
                        placeholder="Enter Name"
                        onChange={(e) => {
                          setformdata({
                            ...formdata,
                            l_name: e.target.value,
                          });
                        }}
                      />
                    </CCol>
                    <CCol md={12} xs={12} className="mb-2">
                      <CFormInput
                        type="text"
                        className="p-3 custom-input"
                        style={{ border: "1px solid #998E81" }}
                        value={formdata?.co_no}
                        id="exampleFormControlInput1"
                        name="co_no"
                        placeholder="Enter Contact Number"
                        onChange={(e) => {
                          setformdata({
                            ...formdata,
                            co_no: e.target.value,
                          });
                        }}
                      />
                    </CCol>
                    <CCol md={12} xs={12} className="mb-2">
                      <CFormInput
                        type="email"
                        className="p-3 custom-input"
                        style={{ border: "1px solid #998E81" }}
                        value={formdata?.l_email}
                        id="exampleFormControlInput1"
                        name="l_email"
                        placeholder="Enter Email Address"
                        onChange={(e) => {
                          setformdata({
                            ...formdata,
                            l_email: e.target.value,
                          });
                        }}
                      />
                    </CCol>

                    <CCol md={12} xs={12} className="mb-2">
                      <CFormInput
                        className="p-3 custom-input"
                        style={{ border: "1px solid #998E81" }}
                        value={formdata?.l_city}
                        type="text"
                        id="exampleFormControlInput1"
                        name="l_city"
                        placeholder="Enter City"
                        onChange={(e) => {
                          setformdata({
                            ...formdata,
                            l_city: e.target.value,
                          });
                        }}
                      />
                    </CCol>
                    {type === undefined && (
                      <CRow className="mb-2 px-3">
                        <CTableDataCell
                          style={{
                            border: "1px solid #998E81",
                            borderRadius: "4px",
                          }}
                        >
                          <CRow className="d-flex align-items-center">
                            <CCol xs={12} sm={3} className="">
                              <CFormLabel
                                className="p-2"
                                style={{ color: "#8a93a2" }}
                              >
                                Select Service
                              </CFormLabel>
                            </CCol>
                            <CCol xs={12} sm={9}>
                              <CFormCheck
                                type="radio"
                                inline
                                name="l_service"
                                id="attestation"
                                label="Attestation"
                                checked={formdata.l_service == 10}
                                onClick={(e) => {
                                  setformdata({
                                    ...formdata,
                                    l_service: 10,
                                  });
                                }}
                              />
                              <CFormCheck
                                type="radio"
                                inline
                                name="l_service"
                                id="apostille"
                                label="Apostille"
                                checked={formdata.l_service == 17}
                                onClick={(e) => {
                                  setformdata({
                                    ...formdata,
                                    l_service: 17,
                                  });
                                }}
                              />
                              <CFormCheck
                                type="radio"
                                inline
                                name="l_service"
                                id="translation"
                                label="Translation"
                                checked={formdata.l_service == 20}
                                onClick={(e) => {
                                  setformdata({
                                    ...formdata,
                                    l_service: 20,
                                  });
                                }}
                              />
                              <CFormCheck
                                type="radio"
                                inline
                                name="l_service"
                                id="visa"
                                label="Visa"
                                checked={formdata.l_service == 8}
                                onClick={(e) => {
                                  setformdata({
                                    ...formdata,
                                    l_service: 8,
                                  });
                                }}
                              />
                            </CCol>
                          </CRow>
                        </CTableDataCell>
                      </CRow>
                    )}
                    <CCol md={12} xs={12} className="mb-2">
                      <CFormTextarea
                        style={{ border: "1px solid #998E81" }}
                        className="p-3 custom-input"
                        value={formdata?.l_message}
                        type="text"
                        id="exampleFormControlInput1"
                        name="l_message"
                        placeholder="Enter Message"
                        onChange={(e) => {
                          setformdata({
                            ...formdata,
                            l_message: e.target.value,
                          });
                        }}
                        rows={5}
                      />
                    </CCol>

                    <CCol style={{ textAlign: "center" }}>
                      <CButton
                        className="submit-button"
                        disabled={step == 0 ? false : true}
                        color="primary"
                        style={{ marginBottom: "25px", padding: "12px 10px" }}
                        onClick={submitData}
                      >
                        Submit
                      </CButton>
                    </CCol>
                  </CForm>
                </div>
              </div>
            </>
          ) : (
            <>
              <CRow className="justify-content-center">
                <CCol md={5} style={{ width: "600px" }}>
                  <CCardGroup>
                    <CCard className="p-4">
                      <CCardBody>
                        <>
                          <CForm
                            className="dsgrid"
                            // noValidate
                            // validated={validated}
                            // onSubmit={handleSubmit}
                            // onSubmit={handleSubmit(onSubmit)}
                          >
                            <CustomImage
                              src={`${Routes.API_HOSTNAME}/images/adminlogo/check.png`}
                              className="mb-3 center_img"
                            />
                            <h2 className="text-center mb-3">Thank You !</h2>
                            <h5 className="text-center mb-3">
                              Your submission has been sent.
                            </h5>
                            <a
                              style={{ cursor: "pointer" }}
                              className="text-center"
                              color="link"
                              onClick={() => {
                                setSubmitResponse(0);
                              }}
                            >
                              Go Back
                            </a>
                          </CForm>
                        </>
                      </CCardBody>
                    </CCard>
                  </CCardGroup>
                </CCol>
              </CRow>
            </>
          )}
          <CModal
            backdrop="static"
            alignment="center"
            visible={existingLeadInsertModal.visible}
            onClose={() =>
              setExistingLeadInsertModal({
                ...existingLeadInsertModal,
                visible: false,
              })
            }
            style={{ borderRadius: 10 }}
          >
            <div
              className="d-flex justify-content-center p-3"
              style={{
                backgroundColor: "#005e9e",
                borderBottom: "1px solid #d7d7d7",
                color: "white",
                textAlign: "center",
              }}
            >
              <CModalTitle>YOU HAVE ALREADY SUBMITTED QUERY</CModalTitle>
            </div>
            <CModalBody
              style={{
                color: "black",
                background: "#ededed",
                borderRadius: "10px",
                lineHeight: "25px",
              }}
            >
              <div className="mb-2 text-center">
                {" "}
                For more Inquiry, <br />
                Please call{" "}
                <span style={{ color: "#cf2e2e" }}>
                  {existingLeadInsertModal.data.assignee_no}
                </span>{" "}
                <br /> Or <br /> Reply to the previous email conversation at{" "}
                <br />
                <span style={{ color: "#cf2e2e" }}>
                  {existingLeadInsertModal.data.assignee_email}
                </span>{" "}
              </div>
            </CModalBody>
            <CModalFooter
              className="d-flex justify-content-center"
              style={{ background: "#cccccc" }}
            >
              <CButton
                className=""
                style={{
                  marginBottom: "6px",
                  marginTop: "6px",
                  background: "#0562a8",
                  color: "#ffffff",
                  fontWeight: 600,
                  padding: "10px 22px",
                  border: "none",
                  borderRadius: "18px",
                }}
                onClick={() => {
                  setExistingLeadInsertModal({
                    ...existingLeadInsertModal,
                    visible: false,
                  });
                }}
              >
                Ok
              </CButton>
            </CModalFooter>
          </CModal>
        </CContainer>
      </div>
    </>
  );
};

export default InquiryForm;
